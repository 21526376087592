import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Mapbox from "../components/mapbox"
import Footer from "../components/footer"
import normalizeCityName from "../utils/normalizeCityName"
import getFrenchPreposition from "../utils/getFrenchPreposition"

export const Head = ({ pageContext }) => {
  const { city } = pageContext
  const formattedCityName = getFrenchPreposition(city)
  const normalizedCityName = normalizeCityName(city)
  const path = `/carte-touristique-${normalizedCityName}`

  return (
    <Seo
      title={`Carte touristique ${formattedCityName}`}
      description={`Explorez notre carte touristique ${formattedCityName} pour découvrir les sites incontournables, une sélection des meilleurs hébergements et des conseils pratiques pour organiser votre visite.`}
      slug={path}
      image={`/thumbs/maps/${normalizedCityName}.png`}
    />
  )
}

const MapPage = ({ pageContext }) => {
  const { city, latitude, longitude } = pageContext
  const normalizedCityName = normalizeCityName(city)

  const cityZoomLevels = {
    berlin: 12,
    bruges: 14,
    florence: 14,
    helsinki: 12,
    "la-valette": 15,
    ljubljana: 14,
    luxembourg: 14,
    nicosie: 14,
    paris: 12,
    prague: 14,
    riga: 14,
    varsovie: 12,
    zagreb: 14,
  }

  // Default zoom level if city is not listed in the object
  const customZoom = cityZoomLevels[normalizedCityName] || 13

  return (
    <Layout content="map">
      <Mapbox
        latitude={parseFloat(latitude)}
        longitude={parseFloat(longitude)}
        city={normalizedCityName} // pass the city name in lowercase to load the correct JSON file
        customZoom={customZoom}
      />
      <Footer city={city} />
    </Layout>
  )
}

export default MapPage
